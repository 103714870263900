import React from "react"

import { FormattedMessage, injectIntl, navigate, Link } from "gatsby-plugin-intl"

import Layout from "../layouts/layout"
import SEO from "../components/seo"

import Herofooter from "../components/Herofooter"

const CookiePolicyPage = ({ intl }) => (
  <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "SEO.LANDING.COOKIE_POLICY.TITLE" })}
        description={intl.formatMessage({ id: "SEO.LANDING.COOKIE_POLICY.DESCRIPTION" })}
        keywords={intl.formatMessage({ id: "SEO.LANDING.COOKIE_POLICY.KEYWORDS" })}
      />

    <div id="landing-cookie_policy">
      <div className="tpnet-main-content">
        <tpnet-content>
          <article className="tpnet-article">
            <article className="tpnet-article-inner">
              <div className="tpnet-article-body">
                <main>
                  <section className="landing-header">
                    <div
                      className="landing-header__nacho landing-header__nacho--default"
                      aria-hidden="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="3463.97px"
                        height="3947.35px"
                        viewBox="0 0 3463.97 3947.35"
                        preserveAspectRatio="xMinYMin"
                      >
                        <defs>
                          <clipPath id="landing-header-nacho_svg__clip-path">
                            <path
                              className="landing-header-nacho_svg__cls-1"
                              d="M-3593.5-2560.43c286.06-63.44 1878.61 2088.8 1793.43 2324.85s-2266.29 720.51-2442.7 542.47c-26-26.3-36.38-105.87-34.34-222.62 11.69-674.45 439.65-2590.53 683.61-2644.7z"
                            ></path>
                          </clipPath>
                          <clipPath id="landing-header-nacho_svg__clip-path-2">
                            <path
                              fill="none"
                              d="M-5206.6-3363.17h4882v3709h-4882z"
                            ></path>
                          </clipPath>
                          <clipPath id="landing-header-nacho_svg__clip-path-3">
                            <path
                              className="landing-header-nacho_svg__cls-1"
                              d="M33.7 3869.38C259.33 4130.56 3298.85 3677.6 3437.6 3362c20.45-46.59-5.48-153.88-65.65-302.79C3024.47 2198.87 1530.45-50.73 1190.5 0 791.92 59.49-192 3608.24 33.7 3869.38z"
                            ></path>
                          </clipPath>
                        </defs>
                        <g
                          clipPath="url(#landing-header-nacho_svg__clip-path)"
                          id="landing-header-nacho_svg__header-nacho"
                        >
                          <g clipPath="url(#landing-header-nacho_svg__clip-path-2)">
                            <path d="M-6731.56-24594.94l-221.11 27884.15L4247.1 3719.53l221.12-27884.15-11199.78-430.32z"></path>
                          </g>
                        </g>
                        <g
                          clipPath="url(#landing-header-nacho_svg__clip-path-3)"
                          id="landing-header-nacho_svg__landing-header-nacho"
                        >
                          <path
                            transform="rotate(-22 1175.988 2165.688)"
                            id="landing-header-background"
                            fill="currentColor"
                            d="M-530.36 155.86h3412.69v4019.63H-530.36z"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <header className="landing-header__text">
                      <div className="landing-header__inner-wrapper">
                        <tpnet-heading
                          text="Veri Güvenliği"
                          for="veri_guvenligi"
                          level="h1"
                          className="light"
                        >
                          <h1>
                            <FormattedMessage id="LANDING.COOKIE_POLICY" />
                          </h1>
                        </tpnet-heading>
                      </div>
                    </header>
                  </section>
                  <div className="content-wrapper">
                    <section className="card">
                      <div className="text-block">
                        <p>
                          <FormattedMessage id="LANDING.PAGE_COOKIE.r01" />
                        </p>

                        <strong>
                          <p>
                            <FormattedMessage id="LANDING.PAGE_COOKIE.r02" />
                          </p>
                        </strong>

                        <p>
                          <FormattedMessage id="LANDING.PAGE_COOKIE.r03" />
                        </p>

                        <p>
                          <FormattedMessage id="LANDING.PAGE_COOKIE.r04" />
                        </p>

                        <strong>
                          <p>
                            <FormattedMessage id="LANDING.PAGE_COOKIE.r05" />
                          </p>
                        </strong>

                        <p>
                          <FormattedMessage id="LANDING.PAGE_COOKIE.r06" />
                        </p>

                        <strong>
                          <p>
                            <FormattedMessage id="LANDING.PAGE_COOKIE.r07" />
                          </p>
                        </strong>

                        <p>
                          <FormattedMessage id="LANDING.PAGE_COOKIE.r08" />
                        </p>

                        <strong>
                          <p>
                            <FormattedMessage id="LANDING.PAGE_COOKIE.r09" />
                          </p>
                        </strong>

                        <ul>
                          <li>
                            <p>
                              <FormattedMessage id="LANDING.PAGE_COOKIE.r10" />
                            </p>
                            <p>
                              <FormattedMessage id="LANDING.PAGE_COOKIE.r11" />
                            </p>
                          </li>
                        </ul>

                        <strong>
                          <p>
                            <FormattedMessage id="LANDING.PAGE_COOKIE.r12" />
                          </p>
                        </strong>

                        <p>
                          <FormattedMessage id="LANDING.PAGE_COOKIE.r13" />
                        </p>

                        <ul>
                          <li>
                            <p>
                              <FormattedMessage id="LANDING.PAGE_COOKIE.r14" />
                            </p>
                            <p>
                              <FormattedMessage id="LANDING.PAGE_COOKIE.r15" />
                            </p>
                          </li>

                          <li>
                            <p>
                              <FormattedMessage id="LANDING.PAGE_COOKIE.r16" />
                            </p>
                          </li>
                        </ul>

                        <strong>
                          <p>
                            <FormattedMessage id="LANDING.PAGE_COOKIE.r17" />
                          </p>
                        </strong>

                        <p>
                          <FormattedMessage id="LANDING.PAGE_COOKIE.r18" />
                        </p>

                        <p>
                          <FormattedMessage id="LANDING.PAGE_COOKIE.r19" />
                        </p>
                        <p>
                          <Link to="/contact">
                            <FormattedMessage id="LANDING.PAGE_COOKIE.r20" />
                          </Link>
                        </p>
                      </div>
                    </section>
                  </div>

                  <Herofooter />
                </main>
              </div>
            </article>
          </article>
        </tpnet-content>
      </div>
    </div>
  </Layout>
)

export default injectIntl(CookiePolicyPage)
